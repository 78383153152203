<template>
  <div class="page-view">
    <special-mob v-if="isMobile"></special-mob>
    <special-pc v-else></special-pc>
  </div>
</template>

<script>
import SpecialMob from "./special-mob.vue";
import SpecialPc from "./special-pc.vue";
import { specialPv } from "../../api/special"
export default {
  components: {
    SpecialMob,
    SpecialPc,
  },
  metaInfo: {
    title: "因美纳讲堂 | 专题",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  created() {
    this.addSpecialPv()
  },
  methods: {
    addSpecialPv() {
      specialPv({
        topicId: this.$route.params.id,
        sourceTag: this.$route.query.st || 'illumina'
      }).then()
    }
  }
};
</script>
