<template>
  <div class="special-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="banner">
        <img :src="info.TopicBanner" alt="" />
        <!-- <div class="special-name">{{info.name}}</div>
        <div class="special-info">
            <h3>{{info.name + '简介'}}</h3>
            <p class="hidden4">{{info.briefIntroduction}}</p>
        </div> -->
      </div>
      <div class="statics" v-if="info.switchShow == 1">
        <div class="statics-item">
          <p class="count">{{ info.activity }}</p>
          <p class="statics-title">课程数量</p>
        </div>
        <div class="statics-item">
          <p class="count">{{ info.viewsNumber }}</p>
          <p class="statics-title">观看人数</p>
        </div>
        <div class="statics-item">
          <p class="count">{{ info.subscribeNumber }}</p>
          <p class="statics-title">预约人数</p>
        </div>
      </div>
      <!-- <div class="special-info">
                <div :class="['content', active ? 'hidden3' : '']" ref="info">{{info.briefIntroduction}}</div>
                <div class="bottom">
                    <div v-if="flag" :class="['btn', active ? '' : 'active']" @click="trigger()"></div>
                </div>
            </div> -->
      <!-- 课程目录 -->
      <div class="catalog">
        <div class="catalog-title">
          <div class="left">
            <i><img src="../../assets/specialList.png" alt="" /></i>
            <span>课程目录</span>
          </div>
          <div v-if="applyBtn" class="right" @click="subscribeAllFn()">
            <i><img src="../../assets/orderBlue.png" alt="" /></i>
            <span>一键预约所有课程</span>
          </div>
        </div>
        <div class="flex-box">
          <pc-video
            v-for="(item, i) in liveList"
            :key="i"
            :data="item"
            @refDataList="getSpecialLive()"
          ></pc-video>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import { specialInfo, specialLive, subscribeAll } from "../../api/special";
import PcVideo from "../../components/pc-video.vue";
export default {
  components: {
    PcHeader,
    PcFooter,
    PcVideo,
  },
  data() {
    return {
      id: this.$route.params.id,
      info: {},
      // active: true,
      // flag: false,
      applyBtn: false,
      liveList: [],
      scourceTag: this.$route.query.st || 'illumina'
    };
  },
  created() {
    this.getSpecialInfo();
    this.getSpecialLive();
  },
  // updated() {
  //     if (this.$refs.info.scrollHeight > this.$refs.info.clientHeight) {
  //         this.active = true
  //         this.flag = true
  //     }
  // },
  methods: {
    subscribeAllFn() {
      subscribeAll({ id: this.id, type: "1" }).then((res) => {
        if (res.code == 0) {
          this.$ylmessage.success("全部预约成功");
          this.getSpecialLive();
        } else if (res.code == 60000) {
          this.$router.push({ path: "/login" });
        }
      });
    },
    // 判断课程有没有未开始的直播，有没有未预约的直播
    forInT() {
      this.applyBtn = false;
      for (let i = 0; i < this.liveList.length; i++) {
        // console.log(liveList[i]['type'] + '---' + liveList[i]['liveStatus'] + )
        if (
          this.liveList[i]["type"] == 0 &&
          this.liveList[i]["liveStatus"] == 0 &&
          this.liveList[i]["ymnSubscribe"] == null
        ) {
          // 直播未开始
          //   // 有未预约的直播
          this.applyBtn = true;
        }
      }
    },
    getSpecialLive() {
      specialLive({ id: this.id }).then((res) => {
        this.liveList = res.data;
        if (this.liveList) {
          this.forInT();
        }
      });
    },
    trigger() {
      //   console.log(this.flag);
      if (this.active) {
        this.active = false;
      } else {
        this.active = true;
      }
    },
    getSpecialInfo() {
      //   console.log(this.id);
      specialInfo({ id: this.id }).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p,
h3 {
  margin: 0;
}
.special-pc {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    .banner {
      position: relative;
      margin-top: 40px;
      height: 538px;
      border-radius: 6px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .special-name {
        position: absolute;
        left: 50px;
        bottom: 30px;
        font-size: 22px;
        color: #fff;
      }
      .special-info {
        position: absolute;
        right: 50px;
        bottom: 80px;
        width: 620px;
        height: 170px;
        background-color: rgba($color: #fff, $alpha: 0.7);
        padding: 30px 40px;
        box-sizing: border-box;
        p {
          margin-top: 10px;
          font-size: 14px;
          color: #333;
        }
      }
    }
    .statics {
      height: 150px;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      .statics-item {
        width: 399px;
        height: 70px;
        text-align: center;
        &:nth-child(2) {
          border: 1px solid #ccc;
          border-top: 0;
          border-bottom: 0;
        }
        .count {
          font-size: 40px;
          height: 40px;
          line-height: 40px;
          font-weight: 700;
          color: #1872bb;
        }
        .statics-title {
          font-size: 16px;
          color: #999;
        }
      }
    }
    // .special-info {
    //   background-color: #fff;
    //   margin-top: 1px;
    //   padding: 0.15rem;
    //   border-radius: 0 0 0.06rem 0.06rem;
    //   .content {
    //     font-size: 0.14rem;
    //     color: #999;
    //     overflow: hidden;
    //     //   text-align: justify;
    //     //   text-align-last: left;
    //   }
    //   .bottom {
    //     text-align: center;
    //     position: relative;
    //     height: 0.2rem;
    //     .btn {
    //       position: absolute;
    //       bottom: -0.06rem;
    //       display: inline-block;
    //       width: 0;
    //       height: 0;
    //       border: 0.06rem solid #fff;
    //       border-top-color: #999;
    //       &.active {
    //         bottom: 0;
    //         border-top-color: #fff;
    //         border-bottom-color: #999;
    //       }
    //     }
    //   }
    // }
    .catalog {
      .catalog-title {
        display: flex;
        justify-content: space-between;
        margin: 50px 0 20px;
        .left,
        .right {
          display: flex;
          img {
            //   width: 100%;
            height: 100%;
          }
        }
        .left {
          display: flex;
          align-items: center;
          i {
            //   width: 0.22rem;
            height: 30px;
          }
          span {
            margin-left: 20px;
            font-size: 20px;
            color: #49b67b;
            font-weight: bold;
            height: 30px;
            line-height: 30px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 230px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #1872bb;
          border-radius: 23px;
          cursor: pointer;
          i {
            height: 20px;
          }
          span {
            margin-left: 10px;
            font-size: 14px;
            color: #1872bb;
          }
        }
      }
      .flex-box {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 65px;
        min-height: 290px;
        .pc-video {
          margin-bottom: 35px;
        }
        .pc-video:nth-child(3n-1) {
          margin: 0 15px 35px;
        }
      }
    }
  }
}
</style>
