<template>
  <div class="special-mob">
    <mob-header></mob-header>
    <div class="main">
      <div class="banner">
        <img :src="info.TopicBanner" alt="" />
      </div>
      <div class="statics" v-if="info.switchShow == 1">
        <div class="statics-item">
          <p class="count">{{ info.activity }}</p>
          <p class="statics-title">课程数量</p>
        </div>
        <div class="statics-item">
          <p class="count">{{ info.viewsNumber }}</p>
          <p class="statics-title">观看人数</p>
        </div>
        <div class="statics-item">
          <p class="count">{{ info.subscribeNumber }}</p>
          <p class="statics-title">预约人数</p>
        </div>
      </div>
      <div class="special-info">
        <div :class="['content', active ? 'hidden3' : '']" ref="info">
          {{ info.briefIntroduction }}
        </div>
        <div class="bottom">
          <div
            v-if="flag"
            :class="['btn', active ? '' : 'active']"
            @click="trigger()"
          ></div>
        </div>
      </div>
      <!-- 课程目录 -->
      <div class="catalog">
        <div class="catalog-title">
          <div class="left">
            <i><img src="../../assets/specialList.png" alt="" /></i>
            <span>课程目录</span>
          </div>
          <div v-if="applyBtn" class="right" @click="subscribeAllFn()">
            <i><img src="../../assets/orderBlue.png" alt="" /></i>
            <span>一键预约所有课程</span>
          </div>
        </div>
        <mob-video
          v-for="(item, i) in liveList"
          :key="i"
          :data="item"
          @refDataList="getSpecialLive()"
        ></mob-video>
      </div>
    </div>
    <mob-footer-bar :active="1"></mob-footer-bar>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import { specialInfo, specialLive, subscribeAll } from "../../api/special";
import MobVideo from "../../components/mob-video.vue";
export default {
  components: {
    MobHeader,
    MobFooterBar,
    MobVideo,
  },
  data() {
    return {
      id: this.$route.params.id,
      info: {},
      active: true,
      flag: false,
      applyBtn: false,
      liveList: [],
      scourceTag: this.$route.query.st || 'illumina'
    };
  },
  created() {
    this.getSpecialInfo();
    this.getSpecialLive();
  },
  updated() {
    if (this.$refs.info.scrollHeight > this.$refs.info.clientHeight) {
      this.active = true;
      this.flag = true;
    }
  },
  methods: {
    subscribeAllFn() {
      subscribeAll({ id: this.id, type: "1" }).then((res) => {
        if (res.code == 0) {
          this.$ylmessage.success("全部预约成功");
          this.getSpecialLive();
        } else if (res.code == 60000) {
          this.$router.push({ path: "/login" });
        }
      });
    },
    // 判断课程有没有未开始的直播，有没有未预约的直播
    forInT() {
      this.applyBtn = false;
      for (let i = 0; i < this.liveList.length; i++) {
        // console.log(liveList[i]['type'] + '---' + liveList[i]['liveStatus'] + )
        if (
          this.liveList[i]["type"] == 0 &&
          this.liveList[i]["liveStatus"] == 0 &&
          this.liveList[i]["ymnSubscribe"] == null
        ) {
          // 直播未开始
          //   // 有未预约的直播
          this.applyBtn = true;
        }
      }
    },
    getSpecialLive() {
      specialLive({ id: this.id }).then((res) => {
        this.liveList = res.data;
        if (this.liveList) {
          this.forInT();
        }
      });
    },
    trigger() {
      //   console.log(this.flag);
      if (this.active) {
        this.active = false;
      } else {
        this.active = true;
      }
    },
    getSpecialInfo() {
      //   console.log(this.id);
      specialInfo({ id: this.id }).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.special-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0 0.1rem 0.8rem;
    .banner {
      margin-bottom: 0.1rem;
      height: 1.6rem;
      border-radius: 0.06rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .statics {
      // margin-top: 0.1rem;
      height: 0.6rem;
      background-color: #fff;
      border-radius: 0.06rem 0.06rem 0 0;
      display: flex;
      align-items: center;
      .statics-item {
        width: 1.19rem;
        height: 0.35rem;
        text-align: center;
        &:nth-child(2) {
          border: 1px solid #ccc;
          border-top: 0;
          border-bottom: 0;
        }
        .count {
          font-size: 0.2rem;
          height: 0.2rem;
          line-height: 0.2rem;
          font-weight: 700;
          color: #1872bb;
        }
        .statics-title {
          font-size: 0.1rem;
          color: #999;
        }
      }
    }
    .special-info {
      background-color: #fff;
      margin-top: 1px;
      padding: 0.15rem;
      border-radius: 0 0 0.06rem 0.06rem;
      .content {
        font-size: 0.14rem;
        color: #999;
        overflow: hidden;
        //   text-align: justify;
        //   text-align-last: left;
      }
      .bottom {
        text-align: center;
        position: relative;
        height: 0.2rem;
        .btn {
          position: absolute;
          bottom: -0.06rem;
          display: inline-block;
          width: 0;
          height: 0;
          border: 0.06rem solid #fff;
          border-top-color: #999;
          &.active {
            bottom: 0;
            border-top-color: #fff;
            border-bottom-color: #999;
          }
        }
      }
    }
    .catalog {
      .catalog-title {
        display: flex;
        justify-content: space-between;
        margin: 0.25rem 0 0.1rem;
        .left,
        .right {
          display: flex;
          img {
            //   width: 100%;
            height: 100%;
          }
        }
        .left {
          display: flex;
          align-items: center;
          i {
            //   width: 0.22rem;
            height: 0.22rem;
          }
          span {
            margin-left: 0.1rem;
            font-size: 0.14rem;
            color: #49b67b;
            font-weight: bold;
            height: 0.22rem;
            line-height: 0.22rem;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.43rem;
          height: 0.25rem;
          line-height: 0.25rem;
          border: 1px solid #1872bb;
          border-radius: 0.13rem;
          i {
            height: 0.14rem;
          }
          span {
            margin-left: 0.05rem;
            font-size: 0.12rem;
            color: #1872bb;
          }
        }
      }
    }
  }
}
</style>
